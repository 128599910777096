import "../../styles/section.css";
import "./menus.css";

import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Trans, useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { Link as AnchorLink } from "react-scroll";

import Layout from "../../components/layout/Layout";
import Seo from "../../components/seo";
import SectionLanding from "../../components/section/SectionLanding";
import Menu from "../../components/carta/Menu";

// util imports
import { anchorLinkProps } from "../../utils/anchorLinkProps";

import FlorituraHoja from "../../svg/florituras/florituraHoja.svg";
import MaskSq1 from "../../svg/masks/maskSq1.svg";
import MaskSq2 from "../../svg/masks/maskSq2.svg";
import MaskSq3 from "../../svg/masks/maskSq3.svg";
import MaskSq4 from "../../svg/masks/maskSq4.svg";
import MaskModalMobile from "../../svg/masks/maskModalMobile.svg";
import IconFlecha from "../../svg/icons/iconFlecha.svg";
import LineaSeparador from "../../svg/lineaSeparador.svg";

export default function IndexPage({ data }) {
	const windowGlobal = typeof window !== "undefined" && window;

	const { t } = useTranslation();

	const [activeLocal, setActiveLocal] = React.useState(false);
	const [activeMenu, setActiveMenu] = React.useState(undefined);

	const locales = React.useMemo(() => {
		return {
			origen: {
				title: t("Origen"),
				address: "Cortes Valencianas 50, Valencia",
				img: (
					<StaticImage
						src="../../images/local/menus/origen.jpg"
						alt={t("An image of the restaurant interior")}
					/>
				),
			},
			profundidadesMarinas: {
				title: t("Profundi­dades Marinas"),
				address: "Pascual i Genis 11, Valencia",
				img: (
					<StaticImage
						src="../../images/local/menus/profundidadesMarinas.jpg"
						alt={t("An image of the restaurant interior")}
					/>
				),
			},
			jardinSecreto: {
				title: t("Jardín Secreto"),
				address: "C. de Martínez Ferrando 5, Valencia",
				img: (
					<StaticImage
						src="../../images/local/colon/0.jpg"
						alt={t("An image of the restaurant interior")}
					/>
				),
			},
			invernadero: {
				title: t("Invernadero"),
				address: "Ramon Llull 1, Valencia",
				img: (
					<StaticImage
						src="../../images/local/menus/invernadero.jpg"
						alt={t("An image of the restaurant interior")}
					/>
				),
			},
			huerta: {
				title: t("Huerta"),
				address: "Paseo de la Alameda 47, Valencia",
				img: (
					<StaticImage
						src="../../images/local/menus/huerta.jpg"
						alt={t("An image of the restaurant interior")}
					/>
				),
			},
			jungla: {
				title: t("Jungla"),
				address: "San Francisco de Borja 26, Gandia",
				img: (
					<StaticImage
						src="../../images/local/menus/jungla.jpg"
						alt={t("An image of the restaurant interior")}
					/>
				),
			},
		};
	}, [t]);

	const localMenus = React.useMemo(() => {
		return {
			origen: {
				carta: true,
				menuDia: true,
				menuGrupo: true,
				menuDegustacion: true,
				// menuDegustacionNavidad: true,
				// menuFallas: false,
			},
			profundidadesMarinas: {
				carta: true,
				menuDia: true,
				menuGrupo: true,
				menuDegustacion: true,
				menuBrunch: true,
				menuFallas: true,
				// menuNavidad: false,
				// menuNochevieja: false,
				// menuDegustacionNavidad: true,
			},
			jardinSecreto: {
				carta: true,
				menuDia: true,
				menuGrupo: true,
				menuDegustacion: true,
				menuBrunchYMini: true,
				menuTarde: true,
				menuFallas: true,
				// menuNavidad: false,
				// menuNochevieja: false,
				// menuDegustacionNavidad: true,
			},
			invernadero: {
				carta: true,
				menuDia: true,
				menuGrupo: true,
				menuDegustacion: true,
				menuEstudiantes: true,
				menuEstudiantesNoche: true,
				// menuGrupoEstudiantes: false,
				// menuDegustacionNavidad: true,
			},
			huerta: {
				carta: true,
				menuDia: true,
				menuGrupo: true,
				menuDegustacion: true,
				menuBrunch: true,
				menuFallas: true,
				// menuNavidad: false,
				// menuNochevieja: false,
				// menuDegustacionNavidad: true,
			},
			jungla: {
				carta: true,
				menuDia: true,
				menuNoche: true,
				menuGrupo: true,
				menuDegustacion: true,
				menuEstudiantes: true,
				menuEstudiantesNoche: true,
				menuFallas: true,
				// menuFestesGandia: true,
				// menuNavidad: false,
				// menuNochevieja: false,
				// menuDegustacionNavidad: true,
			},
		};
	}, []);

	// Hash navigation
	const navigateToContentTop = React.useCallback(() => {
		if (windowGlobal) {
			const contentAnchor = document.getElementById("sectionContent");
			contentAnchor.scrollIntoView();
			// if (linkedLocal !== "") toggleSection(linkedSection);
		}
	}, [windowGlobal]);

	// console.log(activeMenu);

	const setLocalAndMenuByHash = React.useCallback(() => {
		const hash = window?.location?.hash?.split("/");
		const linkedLocal = hash[1];
		const linkedMenu = hash[2];
		// console.log(linkedMenu);

		const setFirsAvailableMenu = (local) => {
			for (const menu in localMenus[local]) {
				if (localMenus[local][menu] === true) {
					setActiveMenu(menu);
					return;
				}
			}
		};

		if (linkedLocal in locales) {
			setActiveLocal(linkedLocal);
			if (localMenus[linkedLocal][linkedMenu] === true) {
				setActiveMenu(linkedMenu);
			} else {
				setFirsAvailableMenu(linkedLocal);
				// setActiveMenu("menuDia");
			}
		} else {
			if (activeLocal !== false) setActiveLocal(false);
			if (activeMenu === undefined) {
				setFirsAvailableMenu(linkedLocal);
			}
		}
	}, [activeLocal, activeMenu, localMenus, locales]);

	if (typeof window !== "undefined") {
		// Update currentSection on hash change
		window.onhashchange = (e) => {
			setLocalAndMenuByHash();
			setTimeout(() => navigateToContentTop(), 400);
		};
	}

	React.useEffect(() => {
		if (windowGlobal && typeof document !== "undefined") {
			setLocalAndMenuByHash();
			window.addEventListener("hashchange", setLocalAndMenuByHash);

			// navigate to content on first mount
			navigateToContentTop();
		}

		return () => {
			window.removeEventListener("hashchange", setLocalAndMenuByHash);
		};
	}, [navigateToContentTop, setLocalAndMenuByHash, windowGlobal]);

	// console.log(alergenos)

	// const [showCategorias, setShowCategorias] = React.useState(false);

	const menus = {
		carta: t("Carta"),
		menuDia: t("Menú de mediodía"),
		menuNoche: t("Menú de noche"),
		// menuGrupo: t("Menú de grupos"),
		menuDegustacion: t("Menú degustación"),
		menuBrunch: t("Menú de brunch"),
		menuEstudiantes: t("Menú estudiantes mediodía"),
		menuEstudiantesNoche: t("Menú estudiantes noche"),
		menuGrupoEstudiantes: t("Menú de grupos de estudiantes"),
		menuFestesGandia: t("Menú de Festes Gandía"),
		menuFallas: t("Menú de Fallas"),
		menuNavidad: t("Menú Navidad"),
		menuNochevieja: t("Menú de Nochevieja"),
		menuDegustacionNavidad: t("Menú degustación navideño"),
		menuBrunchYMini: t("Brunch y mini brunch"),
		menuTarde: t("Menú de tarde"),
	};

	return (
		<Layout>
			<Seo title={t("Menús")} />
			<StaticImage
				src={`../../images/carta.jpg`}
				className="landing-bg"
				alt="Section background"
				layout="fullWidth"
			/>
			{/* <Parallax speed={-30} className="parallaxImg">
				<StaticImage
					src={`../../images/carta.jpg`}
					className="landing-bg"
					alt="Section background"
					layout="fullWidth"
				/>
			</Parallax> */}
			<div className="landingSection">
				<SectionLanding
					title={t("Descubre nuestros menús")}
					subtitle={t("Somos reales, no perfectos")}
					orbitalText={t("Bienestar")}
					section="carta"
				>
					<div className="sectionLandingButtons">
						<AnchorLink
							{...anchorLinkProps}
							offset={0}
							to="sectionContent"
							className="underlineButton big"
						>
							<Trans>Ver cartas</Trans>
						</AnchorLink>
					</div>
				</SectionLanding>
			</div>

			<MaskSq1 />
			<MaskSq2 />
			<MaskSq3 />
			<MaskSq4 />
			<MaskModalMobile />

			<div id="carta" className="section invertColors menu">
				<span id="sectionContent" />
				<SwitchTransition mode="out-in">
					<CSSTransition key={activeLocal} timeout={400} classNames="fade">
						{activeLocal ? (
							<div className="transitionContainer">
								<div className="mainTitleCarta">
									<FlorituraHoja className="floritura" />
									<h1 className="lowercase">
										<Trans>Nuestros menús</Trans> <Trans>en</Trans>
										<i>{" Begin " + locales[activeLocal]?.title}</i>
									</h1>

									<a href="#/" className="iconFlecha" aria-label={t("Atrás")}>
										<IconFlecha />
									</a>
								</div>
								<div className={`cartaTopLinks menuTopLinks`}>
									{Object.keys(menus).map((menu) => {
										if (localMenus[activeLocal][menu]) {
											return (
												<AnchorLink
													{...anchorLinkProps}
													onClick={() => {
														if (window) {
															window.location = "#/" + activeLocal + "/" + menu;
														}
													}}
													to="cartaBeginning"
													key={menu}
													className={`${activeMenu === menu ? "active" : ""}`}
												>
													<h2>
														{menus[menu]}
														{/* <Circulo1 /> */}
													</h2>
												</AnchorLink>
											);
										}
										return null;
									})}
								</div>
								<LineaSeparador className="menuSeparador" id="cartaContents" />
								<Menu
									activeLocal={activeLocal}
									activeMenu={activeMenu}
									menus={menus}
								/>
							</div>
						) : (
							<div className="transitionContainer">
								<div className="mainTitleCarta">
									<FlorituraHoja className="floritura" />
									<h1 className="lowercase">
										<Trans>¿Qué local quieres visitar?</Trans>
									</h1>
									<h2 className="lowercase">
										<Trans>
											Descubre todas las opciones de nuestros menús
											especialmente pensados para que disfrutes de una
											experiencia única en Begin.
										</Trans>
									</h2>
								</div>

								<div className="localesGrid">
									{Object.keys(locales).map((local) => {
										return (
											<a href={"#/" + local} key={local}>
												<div className="localImg">{locales[local].img}</div>
												<h2>{locales[local].title}</h2>
												<h3>{locales[local].address}</h3>
											</a>
										);
									})}
								</div>
							</div>
						)}
					</CSSTransition>
				</SwitchTransition>
				<FlorituraHoja className="footerFloritura" />
			</div>
		</Layout>
	);
}

// Cuando carta dé errror, eliminar menuDegustacionNavidad y gatsby clean + redevelopear
export const query = graphql`
	fragment platoDetails on BeginCartaCartaExportCsv {
		categoria
		adaptable
		alergenos
		azucares
		calkJ
		carbs
		calkcal
		colesterol
		cuidadoyorigen_en
		cuidadoyorigen_es
		descripcion_en
		descripcion_es
		fibra
		funcionalidad_en
		funcionalidad_es
		tipo
		grasas
		grasasmono
		grasassat
		grasaspoli
		grasastrans
		id
		caracteristicas
		ingredientes
		nombreplato_en
		nutricion
		nombreplato_es
		personas
		pesoracionkg
		precio
		precioCopa
		prot
		sellos
		sodio
		tamanoraciongramos
		vitaminas
		cartaNota_es
		cartaNota_en
		menuNota_es
		menuNota_en
		menuBrunch
		menuMiniBrunch
		menuTarde
		menuDegustacion
		menuDia
		menuDia_jungla
		menuDia_huerta
		menuDia_invernadero
		menuDia_profundidadesMarinas
		menuGrupo
		menuFallas
		menuNavidad
		menuNochevieja
		menuDegustacionNavidad
		menuNoche_jungla
		menuEstudiantes
		menuEstudiantesNoche
		menuGrupoEstudiantes
		menuFestesGandia
		imagen {
			childImageSharp {
				gatsbyImageData
			}
		}
	}

	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
